.contenedorCardTotal {
  background: var(--fondo);
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  box-shadow: var(--sombra);
  border-radius: 3px;
  align-items: center;
  min-width: 290px;

  .tituloResCompra {
    justify-content: center;
    display: flex;
  }

  .lineaTotal {
    width: 75%;
    margin-inline: auto;
  }
  .contenedorBtnTot {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    .btnTotStylePrimary {
      border: none;
      color: var(--primario);
      padding: 8px;
      margin-top: 4%;
      background: var(--btnBackground);
      border-radius: 4px;
      font-size: 14px;
    }
    .btnTotStylePrimary:hover {
      background-color: #2d5e74d9;
    }

    .transparent {
      background: transparent;
      color: #2d5e74;
      border: 1px solid;
      padding: 8px;
      margin-top: 4%;
      border-radius: 4px;
      font-size: 14px;
    }

    .btnTotStyleSecondary {
      background-color: transparent;
      border: 2px solid #ff4500;
      color: #ff4500;
      margin-top: 4%;
      border-radius: 4px;
      font-size: 14px;
      padding: 8px;
    }
  }
}

@media (max-width: 768px) {
  .sticky-footer {
    position: fixed;
    bottom: 0;
    background: var(--fondo);
    box-shadow: var(--sombra);
    padding: 16px;
    z-index: 1; /* Ensure it stays on top */
    left: 0;
    right: 0;
  }
}
