/* The switch - the box around the slider */
.switch {
  position: relative;
  display: flex;
  height: 25px;
  gap: 4px;

  .label {
    align-content: center;
    cursor: pointer;
  }
}

/* Hide default HTML checkbox */
.switch input {
  visibility: hidden;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  width: 40px;
  cursor: pointer;
  background-color: #ccc;
  position: relative;
  transition: 0.4s;
  box-shadow:
    3px -1px 2px 0px inset #0000006b,
    2px 2px 1px -1px inset #000000;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 7px;
  bottom: 5px;
  background-color: white;
  transition: 0.4s;
  box-shadow:
    3px 1px 3px -1px #000000,
    2px 2px 3px -1px inset #000000;
}

input:checked + .slider {
  background-color: #3ba440;
}

input:focus + .slider {
  box-shadow: 0 0 1px #29576c;
}

input:checked + .slider:before {
  transform: translateX(9px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
