.containerCarrito {
  /* width: 90%;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  margin: 16px auto; */

  padding: 0 24px;
  .carrotitle {
    font-size: 24px;
    color: var(--tituloColor);
    font-weight: 300;
    padding: 8px;
    box-shadow: var(--sombra);
    background: #fff;
    margin-bottom: 16px;
  }
  .cardMembrete {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr; /* Un solo columna */
    background: var(--fondo);
    box-shadow: var(--sombra);
    border-radius: 4px;
    padding: 16px;
    .tituloMembrete {
      font-size: 24px;
      margin: 0;
      color: var(--tituloColor);
      font-weight: 300;
      padding: 8px;
    }
    .bodyMembrete {
      display: grid;
      grid-template-areas: 'izquierda centro derecha';
      gap: 8px;
      grid-template-columns: repeat(3, 1fr);
      text-align: left;
      .columnasInfoMembrete {
        flex: 1; /* Hace que las columnas se expandan para ocupar el espacio disponible */
        font-size: 12px;
        color: var(--constrast-dark);
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
    @media (max-width: 500px) {
      .bodyMembrete {
        grid-template-areas:
          'izquierda'
          'centro'
          'derecha';
        grid-template-columns: 1fr; /* Solo una columna */
      }
    }
  }

  .tarjetas {
    grid-area: tarjetas;
  }
  .comprobyresume {
    grid-area: comprobyresume;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .contenedorCarrito {
    display: grid;
    grid-template-areas: 'tarjetas comprobyresume';
    gap: 24px;
    grid-template-columns: 2fr 1fr;
    margin-top: 24px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .contenedorCardCarrito {
      display: grid;
      grid-template-columns: 1fr;
      padding: 16px;
      background: var(--fondo);
      box-shadow: var(--sombra);
      border-radius: 4px;
      height: fit-content;
      margin-bottom: 16px;

      h2 {
        margin: 0;
      }
      .cardCarrito {
        display: grid;
        grid-template-areas:
          'cabecera descuento selector precio'
          'delete descuento selector precio';
        grid-template-columns: 7fr 1fr 1fr minmax(120px, 2fr);
        gap: 16px;
        justify-items: stretch;
        .imgContenedorCarrito {
          grid-area: imagen;
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          .imgCarrito {
            max-width: 100px;
            max-height: 100px;
          }
        }
        .contenedorTituloBtn {
          grid-area: cabecera;
          text-align: left;
          gap: 8px;
          &.tituloCard {
            font-size: 16px;
            line-height: 1.5;
            font-weight: 400;
          }
        }
        .btnEliminarCard {
          grid-area: delete;
          border: none;
          background: transparent;
          color: #0056b3;
          font-size: 12px;
          font-weight: 300;
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .contenedorDescuento {
          grid-area: descuento;
          display: flex;
          align-items: center;
          font-size: 14px;
          gap: 4px;
          .carritoDescuento {
            display: flex;
            align-items: baseline;
            gap: 4px;
            font-weight: 400;
          }
          .selectordesc {
            width: 24px;
            height: 24px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            text-align: center;
            background: none;
          }
          .selectordescexed {
            color: red;
            width: 24px;
            height: 24px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            text-align: center;
            background: none;
          }
        }

        .contSelectorTot {
          grid-area: selector;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          gap: 8px;
          .selectorCant {
            display: flex;
            align-items: center;
            .selectorItems {
              width: 24px;
              height: 24px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 3px;
              text-align: center;
              background: none;
              font-size: 14px;
              > img {
                width: 100%;
              }
              &:hover {
                background: #f1f1f1;
              }
            }
          }
          .selectorStock {
            font-size: 11px;
          }
        }
        .selectorTotal {
          grid-area: precio;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          .handleTotDesc {
            font-size: 12px;
            margin-bottom: 4px;
            .CantDesc {
              margin-right: 4px;
              color: #00a650;
            }
            .totSinDesc {
              text-decoration: line-through;
            }
          }
          .totalItems {
            font-size: 16px;
            font-weight: 600;
            color: #80808b;
          }
        }
      }
      .alert {
        display: flex;
        gap: 8px;
        padding: 10px;
        margin: 10px;
        font-weight: 400;
        justify-content: center;
        background-color: #ffa50080;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .warningIcon {
          font-size: 24px;
          color: #ff4500;
        }
        .alertText {
          color: #333333;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}

.empty-screen {
  display: flex;
  height: 30%;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  div a {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .containerCarrito {
    & .contenedorCarrito {
      grid-template-areas: 'tarjetas' 'comprobyresume';
      & .contenedorCardCarrito {
        .cardCarrito {
          grid-template-areas:
            'cabecera precio'
            'descuento selector'
            'delete selector';
          grid-template-columns: 3fr minmax(80px, 1fr);
          justify-items: stretch;
        }
        .alert {
          animation-name: none;
          -webkit-animation-name: none;
        }
      }
    }
  }

  .containerCarrito {
    padding: 0 8px;
    padding-bottom: 200px;
  }
}
