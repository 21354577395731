.GrandContainerGestionP {
  margin-top: 1%;
  font-size: var(--size-14);
  padding: 0 16px;
  > h1 {
    font-size: 24px;
    margin: 0;
    color: var(--tituloColor);
    font-weight: 300;
    padding: 8px;
  }
  .cardManagementProfile {
    /* display: flex; */
    display: grid;
    grid-template-areas: 'img nombre perfiles';
    grid-template-columns: 60px 0.3fr 1fr;
    align-items: center;
    gap: 8px;
    justify-items: center;
    color: var(--constrast-dark);
    font-size: var(--size-14);
    gap: 8px;
    padding: 8px;
    transition: background 0.2s ease-in-out;
    cursor: pointer;

    &:nth-child(even) {
      background-color: #29576c12;
    }
    .imgGestPerfil {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      grid-area: img;
    }
    .nombreGestPerfil {
      grid-area: nombre;
      width: 100%;
      text-align: start;
      font-weight: 600;
    }
    .perfilesGest {
      grid-area: perfiles;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      > div {
        width: 100%;
      }
    }
  }
  .cardManagementProfile:hover {
    background: #eef5ff;
    border-radius: 4px;
  }
}

.acordeon-items.abierto .acordeon-contenido {
  display: block;
}

button#openfilterProfiles {
  display: flex;
  align-items: baseline;
  width: 100%;
  > h2 {
    font-size: var(--size-14);
    font-weight: bolder;
  }
  > span {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  button#openfilterProfiles {
    flex-direction: column;
  }
}
/* button#openfilterProfiles:hover {
  color: var(--secundario);
} */
button#openfilterProfiles svg {
  transition: transform 0.3s;
}

.filterProfilesNameImg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  > span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.btnSaveProfile {
  border: none;
  background: #0056b3;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-top: 2px;
  gap: 8px;
  > img {
    width: 16px;
  }
}
