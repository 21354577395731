.contact-page {
  width: 50%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px;

  header {
    font-size: 46px;
  }

  h2,
  h3 {
    margin: 0;
  }

  h2 {
    line-height: 1.5;
    text-align: center;
  }

  .contact-form {
    padding: 16px;
    border-radius: 5px;
    position: relative;
    background: linear-gradient(to right, #795d01, #a47d00, #a47d00, #795d01);
    overflow: hidden;

    h3 {
      color: white;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1.5;
      letter-spacing: 8px;
    }
    a {
      padding: 8px 16px;
      background-color: white;
      color: #a47d00;
      border-radius: 3px;
      margin-top: 16px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        box-shadow: 2px 2px 1px #0000006b;
      }
    }
  }

  .contact-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 300;
  }
}

@media (max-width: 768px) {
  .contact-page {
    width: 100%;
  }
}
