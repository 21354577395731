.tituloResCompra {
  justify-content: center;
  display: flex;
}
.contenedorTotal {
  width: 100%;
  .summary {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    width: 100%;
    font-size: 14px;
    .resumeItems {
      gap: 8px;
      display: flex;
      justify-content: space-between;
      .styleTotal {
        font-weight: bold;
      }
    }
  }
}
