.modal-perfiles {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalcontent-perfiles {
  margin: 10% auto;
  border: 1px solid #888;
  width: 40%;
  border-radius: 4px;
  background-color: #fefefe;
}

.modal-interior {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;
  > label {
    display: flex;
    gap: 8px;
    cursor: pointer;
  } 
  
}
.custom-checkbox {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  margin: 4px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  color: #fff;
  font-size: 10px;
  text-align: center;
  top: 0;
  left: 0;
  height: 20px;
  width: 70px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: green;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

