.acordeon-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 5px;
  transition: transform 0.3s ease;
  font-weight: 300;
  color: black;
}

.acordeon-item {
  svg {
    width: 13px;
    height: 13px;
  }
  &.abierto .acordeon-titulo svg {
    transform: rotate(90deg);
  }
}

.acordeon-contenido {
  width: 100%;
  color: var(--constrast-dark);
}

.desc {
  width: 100%;
  text-align: left;
  padding: 0;
}

.stock-y-prec {
  width: 100%;
  min-width: 150px;
  text-align: right;
  padding: 0;
}

/*#.00111487.#   MsPortal   front  CRUD USUARIOS PERFILES*/
.miDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  label {
    cursor: pointer;
    margin-left: 8px;
  }
}

.acordeon-items {
  display: flex;
  justify-content: end;
}

.acordeon-titulos {
  display: flex;
  justify-content: end;
}

.acordeon-items.abierto .acordeon-titulos svg {
  transform: rotate(90deg);
}
