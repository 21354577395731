.div-responsive {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.detailico {
  cursor: pointer;
}

.h4_edit {
  color: white;
}

.file-select {
  position: relative;
  background-color: white;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: black;
}

.file-select::before {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  content: 'Seleccionar';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.1s ease;
}

.file-select:hover {
  color: white;
  background: linear-gradient(
    265deg,
    rgba(83, 171, 215, 0.6615021008403361) 0%,
    rgba(41, 87, 108, 1) 100%
  );

  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-select::before:hover {
  color: white;
}

.file-select input[type='file'] {
  opacity: 0;
  color: white;
}

.file-select input[type='file']:hover {
  color: white;
}

#src-file1::before {
  content: 'Seleccionar Archivo 1';
}

.suboImagen {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.correo {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.tiempo-message {
  background-color: rgb(181 181 181 / 12%);
  border-radius: 5px;
  font-size: small;
  padding: 5px;
  margin-bottom: 8px;
}
.volver {
  text-align: left;
  transition: background-color 0.8s;
}
.volver i:hover {
  color: black;
  text-transform: uppercase;
  transform: scale(1.2);
}

.nombresolo {
  display: none;
}

.foto_input {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 206px;
}

.foto_archi {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.info_carta_perfil {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*.tabla_ico{}*/
/*MENSAJES AUTH*/
.error_msg {
  background-color: rgba(255, 0, 0, 0.116);
  border-radius: 5px;
  color: black;
  font-size: small;
}

.error_msg > p {
  padding: 5px;
}

/* .error-message {
  background-color: rgba(84, 228, 228, 0.116);
  border-radius: 5px;
  font-size: small;
  padding: 5px;
} */

.edit_perfil_box {
  width: 70vw;
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}

/*INPUT EDIT PERFIL*/

.carta_perfil_contenedor label {
  text-align: center;
}

.edit_foto {
  display: flex;
}

.carta_perfil_contenedor input[type='text'],
.carta_perfil_contenedor input[type='email'],
.carta_perfil_contenedor input[type='date'],
.carta_perfil_contenedor input[type='password'] {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
  text-align: left;
}
/*INPUT EDIT PERFIL*/

.label-edit {
  margin-top: 5px;
  /* padding: 5px; */
  text-align: initial;
}

.edit_perfil {
  /*background-color: #ffffff;*/
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: transparent;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}
.carta_perfil {
  display: flex;
  flex-direction: column;
}

.imagen_perfil_edit {
  border-radius: 100%;
  cursor: pointer;
}

.hr_edit_perfil {
  background: gray;
  color: gray;
  border-color: gray;
  height: 0.1px;
}

.spinner svg {
  color: var(--constrast-dark);
  margin-top: 20vh;
}

.error-message-pass {
  background-color: rgba(255, 0, 0, 0.116);
  border-radius: 5px;
  font-size: small;
}

.error-message-pass > p {
  padding: 5px;
}

.info_carta_perfil_foto {
  display: flex;
  justify-content: space-between;
}
/*MENSAJES AUTH*/

/*BOTON PASS ver o no ver*/

.toggle-password-button {
  background: transparent;
  border: transparent;
  opacity: 0.3;
}

.passinput {
  display: flex;
  align-items: baseline;
}

/*BOTON PASS ver o no ver*/
.form-control input {
  width: 30px;
}

.input-cant input {
  width: 20px;
}

.toggle-password {
  /* border: none; */
  /* width: 100%; */
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 0px 5px 5px 0px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/*MODAL PERFIL*/

.modal {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.btn_alta_permiso {
  margin-left: 100px;
}
.modal-content {
  background-color: #2f6178;
  /* margin: 10% auto; */
  margin: 190px auto;
  border: 1px solid #888;
  width: 30%;
  border-radius: 15px;
}

.modal-inside {
  background: var(--colorPagPrimario);
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
}

.modal-inside h2 {
  color: whitesmoke;
  font-size: 150%;
}

.modal-inside span {
  justify-content: end;
  margin-left: auto;
  padding-right: 5px;
}

.imagen_perfil {
  border-radius: 100%;
  cursor: pointer;
}

.info-perfil {
  width: 100%;
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 0 0 15px 15px;
}

.info-perfil h3 {
  color: #2f6178;
}

.info-perfil-inside {
  display: flex;
  justify-content: space-between;
  border-top: 1px;
  border-color: grey;
}

.info-perfil-inside h4 {
  color: #6c757d !important;
}

.info-perfil-inside-input {
  font-size: medium;
  border: none;
  background: whitesmoke;
  text-align: right;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  height: 0;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.checkcontrol {
  display: flex;
  justify-content: space-around;
}

.m-t-sm {
  display: flex;
  justify-content: space-between;
}

/*MODAL PERFIL FIN*/

/*MODAL EDIT PERFIL*/

.modal-contenido-perfil {
  display: flex;
  flex-direction: column;
}

.modal-content-perfil {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: 10% 10% 10% 25%;
  padding: 10px;
  border: 1px solid #888;
  /* width: 75%; */
  /* max-width: 50%; */
  display: flex;
  border-radius: 4px;
  position: relative;
  /* left: 17%; */
}

/*MODAL EDIT PERFIL*/

/*MODAL art*/
/* Se llevo todo 'MODAL ART' a articulo.css */
/*MODAL art fin*/

/* EZE: ESTAS CLASES LAS ENCONTRE EN NUEVOSUSUARIOS.JSX PERO USARE OTRAS CLASES, ESTAS LAS DEJO POR LAS DUDAS QUE ROMPA OTRA COSA.*/
.ibox-content {
  clear: both;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}

.ibox-title {
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  margin-top: 5px;
  padding: 15px 15px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 10px 10px 0 0;
}

.ibox-content-final {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 10px 10px;
}
/* EZE FIN OBSERVACION ---------------------------------------------------*/
.row {
  display: flex;
}

/*div {
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
  }*/
.carro-contenedor {
  display: flex;
  justify-content: space-between;
}

.eliminar-item {
  text-align: right;
}

.input-cant {
  text-align: right;
}
/*CHOOSE FILE*/
.form .imagen {
  color: aquamarine;
  border: #2f6178;
}

/*CHOOSE FILE FIN*/

.li-ini {
  color: transparent;
}

.form-control-cant {
  width: 50%;
  border: none;
  text-align: center;
}

/*MEDIAS QUERIES NICO - AUTH*/

/* CELULARES MOVILES */
@media (max-width: 480px) {
  .modal-content {
    /*margin-top: 164px;*/
    width: 95%;
  }

  .row {
    flex-direction: column;
  }

  .div-responsive {
    flex-direction: column;
  }

  .imagen_carro {
    text-align: center;
  }

  .modal-content-art {
    /*width: 95%;*/
    flex-direction: column;
  }

  .navbar .imagen_logo {
    display: none;
  }

  /*FORMUALRIO*/
  .contenedor {
    justify-content: center;
    display: block;
  }

  .logo {
    flex-basis: 65%;
  }

  .formulario label {
    display: block;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .formulario h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .nameUser {
    display: none;
  }

  .nombresolo {
    display: block;
  }

  /* .formulario input[type='text'],
  .formulario input[type='email'],
  .formulario input[type='password'] {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  } */

  /* button:not(:disabled) { //Eze: Comento estilo que rompia otros, usar clases y no nombre de etiqueta.
    cursor: pointer;

    padding: 10px;
    font-size: 13px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  } */

  /* .formulario input[type='submit'] {
    width: 60%;
    padding: 10px;
    font-size: 13px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  } */

  .formulario input[type='file'] {
    font-size: 13px;
  }

  .error-message {
    font-size: 10px;
  }

  .formulario a {
    font-size: 10px;
  }

  .info_carta_perfil {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .info_carta_perfil_foto {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: space-between;
  }

  .imagen_perfil_edit {
    display: none;
  }

  .label-edit {
    font-size: small;
  }

  .carta_perfil_contenedor input[type='text'],
  .carta_perfil_contenedor input[type='email'],
  .carta_perfil_contenedor input[type='date'],
  .carta_perfil_contenedor input[type='password'] {
    margin-bottom: 0px;
    margin-top: 0px;
    border-radius: 5px;
    border: none;
    text-align: left;
  }
}

.navbar-ini .img {
  height: 26px;
}

/* BAJA RESOCION, IPAD TABLET */
@media (max-width: 480px) {
  .modal-content {
    width: 95%;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .modal-content {
    width: 75%;
  }
  .navbar-ini .img {
    height: 26px;
  }
  /* .imagen_logo{ se comenta porque el logo del sidebar queda muy chico en esta querie
    height: 4vh;
  } */

  .info_carta_perfil {
    display: flex;
  }
  .imagen_perfil_edit {
    display: none;
  }
}

/* TABLETAS Y IPAD LANDSCAPE */
@media (min-width: 768px) and (max-width: 1024px) {
  .modal-content {
    width: 50%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-3,
  .col-md-9 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .info_carta_perfil {
    display: flex;
    justify-content: space-between;
  }

  .info_carta_perfil_foto {
    display: flex;
    justify-content: space-between;
  }
}

/* NOTEBOOKS Y ESCRITORIO */
@media (min-width: 1025px) and (max-width: 1280px) {
  .info_carta_perfil {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info_carta_perfil_foto {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* PANTALLAS GRANDES */
@media (min-width: 1281px) {
  .navbar-ini {
    padding-left: 70px;
    padding-right: 70px;
  }
  .info_carta_perfil {
    display: flex;
    justify-content: space-between;
  }

  .info_carta_perfil_foto {
    display: flex;
    justify-content: space-between;
  }
}
