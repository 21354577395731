.cardTitleComprob {
  padding: 16px;
  background: var(--fondo);
  box-shadow: var(--sombra);
  border-radius: 4px;
  height: fit-content;
}
.cardComprob {
  padding: 16px;
  background: var(--fondo);
  box-shadow: var(--sombra);
  border-radius: 4px;
  height: fit-content;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
}

.cardComprob:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.cardComprob.active {
  background-color: #f0f0f0;
}

.textleft {
  text-align: left;
}

input[type='radio'] {
  pointer-events: none; /* Hace que el input no reciba eventos de clic */
}
