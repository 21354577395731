.pageConfirmContainer {
  padding: 0 24px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  font-size: var(--size-14);
  /* padding: 8px; */
  .historialTitu {
    font-size: 24px;
    color: var(--tituloColor);
    font-weight: 300;
    padding: 8px;
    box-shadow: var(--sombra);
    background: #fff;
    margin-bottom: 16px;
  }

  .contenedorCardConfirm {
    display: grid;
    padding: 4px;
    background: var(--fondo);
    border-radius: 4px;
    transition: background 0.2s ease-in-out;
    &:nth-child(even) {
      background-color: #29576c12;
    }
  }
  .contenedorCardConfirm:hover {
    background: #eef5ff;
    border-radius: 4px;
  }

  .cardConfirm {
    display: grid;
    grid-template-areas: 'fecha sucNum denominacion total';
    grid-template-columns: 100px 180px 1fr 100px;
    align-items: center;
    justify-items: center; /* Centra horizontalmente todos los hijos */
    color: var(--constrast-dark);
    font-size: var(--size-14);
    gap: 8px;
    padding: 8px;
    .fechaPed {
      grid-area: fecha;
    }
    .contenedorSucNum {
      grid-area: sucNum;
      .clase-prv {
        background: #7ba598e8;
        color: white;
        border-radius: 3px;
        padding: 2px;
      }
      .clase-pev {
        background: #52aed8;
        color: white;
        border-radius: 3px;
        padding: 2px;
      }
    }
    .contenedorDenom {
      grid-area: denominacion;
      justify-self: start;
      > button {
        border: none;
        background: none;
      }
      .denominacion {
        font-size: var(--size-14);
        text-overflow: ellipsis;
        /* display: -webkit-box; */
        /* -webkit-line-clamp: 2; */
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--secundario);
        font-weight: 400;
      }
    }
    .totalItems {
      grid-area: total;
      margin-top: 0;
      margin-bottom: 0;
      color: var(--constrast-dark);
      font-weight: 600;
      font-size: var(--size-14);
      justify-self: end;
    }
    /* .contenedorEstado {
      grid-area: estado;
      display: flex;
      align-items: center;
      .estado {
        color: white;
        border-radius: 3px;
        padding-right: 1%;
        padding-left: 1%;
      }
      .entregado {
        background: #418545;
      }
      .pendiente {
        background: #eb1808;
      }
    } */
    /* .contenedorBtnVer {
      grid-area: btn;
      display: flex;
      justify-content: center;
      .btnVer {
        border: none;
        color: var(--primario);
        padding: 8px;
        background: var(--btnBackground);
        border-radius: 4px;
        display: flex;
        cursor: pointer;
        .imglupa {
          width: 20px;
        }
      }
    } */
  }
  /* Media query for screens smaller than 500px */
}
@media (max-width: 768px) {
  .pageConfirmContainer {
    gap: 16px;
    .historialTitu {
      margin-bottom: 0;
    }
    .contenedorCardConfirm {
      box-shadow: var(--sombra);
    }
    .cardConfirm {
      grid-template-areas:
        'denominacion fecha'
        'sucNum total';
      grid-template-columns: 1fr 0.5fr;
      grid-template-rows: auto;
      justify-items: center;
      padding: 16px;
    }

    .contenedorDenom,
    .contenedorSucNum {
      text-align: start;
    }
    .fechaPed,
    .totalItems {
      text-align: end;
    }
    .fechaPed,
    .contenedorSucNum,
    .contenedorDenom {
      font-size: 12px;
    }
    .fechaPed,
    .contenedorSucNum,
    .contenedorDenom,
    .totalItems {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
@media (max-width: 400px) {
  .pageConfirmContainer {
    width: 100%;
  }
}
