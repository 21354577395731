

/* .list__item{
        list-style: none;
        width: 100%;
        text-align: center;
        overflow: hidden;
    } */
.list__item--click {
  cursor: pointer;
}
.list__button {
  display: flex;
  align-items: center;
}

.nav__link {
  color: #303440;
  display: block;
  padding: 15px 0;
  text-decoration: none;
}

.nav__link--inside {
  border-radius: 6px;
  padding-left: 20px;
  text-align: left;
}

.nav__link--inside:hover {
  /* background: #F6F8FA; */
  background: rgba(0, 86, 179, 0.1);
}

.list__show {
  /* width: 80%; */
  margin-left: auto;
  border-left: 2px solid #303440;
  list-style: none;
  transition: height 0.4s;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.list__inside {
  display: flex;
  margin-left: 3%;
}

.botonfiltro {
  background-color: #2f6178;
  color: white;
  border: none;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.faXmark {
  cursor: pointer;
}

.flechasubir {
  border: none;
}
