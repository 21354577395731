.contenedorIniciarCompra {
  display: flex;
  flex-direction: column;
  align-items: start;
  .clienteContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'client close';
    background: var(--fondo);
    margin-top: 1%;
    box-shadow: var(--sombra);
    border-radius: 4px;
    border-left: 6px solid green;
    /* padding: 8px; */

    .btnEliminarCliente {
      grid-area: close;
      border: none;
      background: transparent;
      display: grid;
    }
  }
  .btnIniciarCompra {
    border: none;
    color: var(--primario);
    padding: 8px;
    background: var(--btnBackground);
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: var(--size-14);
    line-height: 17px;

    > img {
      width: 16px;
    }
  }
  .textoAzul {
    grid-area: client;
    color: #2f6178;
    font-size: 12px;
    font-weight: 400;
    border: none;
    padding: 8px;
    line-height: 1.1;
  }
  .textoGris {
    color: #808080;
    font-size: 14px;
    font-weight: lighter;
    border: none;
  }
}
