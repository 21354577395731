.print-order {
  .pageMailContainer {
    padding: 0 24px;
    width: 80%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    .mailTitu {
      font-size: 24px;
      color: var(--tituloColor);
      font-weight: 300;
      padding: 8px;
      box-shadow: var(--sombra);
      background: #fff;
    }
  }
  .headerPrint {
    display: flex;
    justify-content: center;
    gap: 8px;
    font-size: var(--size-14);
    margin: 8px;
  }

  .notaPrint {
    font-style: italic;
    color: #666;
    font-size: var(--size-14);
  }
  .denominacion {
    font-size: 16px;
    color: var(--tituloColor);
    font-weight: normal;
  }
  .tablaHistorial {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .fontTablaModal {
    font-size: 12px;
  }
  .widthTablaModal {
    width: 6rem;
  }
  .widthTablaModalDesc {
    width: 4rem;
  }
  .denomTablaModal {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }

  .contenedorCardTotal {
    background: var(--fondo);
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    box-shadow: var(--sombra);
    border-radius: 3px;
    align-items: center;
    min-width: 290px;
  }
  .totalPrint {
    color: var(--constrast-dark);
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  .printLeyenda {
    font-size: var(--size-14);
    margin: 8px;
  }
  .btn-enabled {
    border: none;
    color: var(--primario);
    padding: 8px;
    margin-top: 4%;
    background: var(--btnBackground);
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
  }
  .btn-enabled:hover {
    background-color: #2d5e74d9;
  }
  .btn-disabled {
    background-color: transparent;
    border: 2px solid #ff4500;
    color: #ff4500;
    margin-top: 4%;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px;
    width: 100%;
  }
  .footerPrint {
    text-align: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    .buttonPrint {
      border: none;
      color: var(--primario);
      padding: 8px;
      background: var(--btnBackground);
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: var(--size-14);
      line-height: 17px;
      cursor: pointer;
      gap: 8px;
      .imgPrintBtn {
        width: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .stickyContenedor {
    position: fixed;
    bottom: 0;
    background: var(--fondo);
    box-shadow: var(--sombra);
    padding: 16px;
    z-index: 1; /* Ensure it stays on top */
    left: 0;
    right: 0;
  }
}
