.tabla {
  width: 70%;
}

.tabla_perfil_usuario {
  display: flex;
  flex-direction: column;
}

.encabezados_perfil {
  display: flex;
}

.itemPerfil {
  display: flex;
  justify-content: center;
}

.tabla__encabezado {
  /*altura del encabezado de la tabla, fondo, color de letra, y alineacion de titulos.*/
  background-color: var(--colorPagPrimario);
  color: #ffffff;
  text-align: center;
  font-size: var(--size-14);
  border-spacing: 0;
  padding: 4px;
}

.tabla__titulo {
  font-weight: normal;
  padding: 8px;
  /*manejo la letra, que sea normal y no negrita*/
}

/*las columnas tienen medidas de ancho que las manejo con estas clases*/
/*Le di nombres en funcion de la medida de cada etiqueta, por eso tabla media y tabla chica, la chica es la mitad de la otra*/
/*La columna Descripcion es la unica que no tiene un ancho definido con clases en mi tabla*/
.tabla__media {
  width: 6rem;
  text-align: left;
}

.tabla__titucant {
  width: 6rem;
}

.tabla__chica {
  width: 3rem;
}

/*tabla precio maneja el ancho de la columna que contenga el valor del producto.*/

.tabla__precio {
  width: 8rem;
}

/*tabla importe maneja el ancho de la columna que contenga el total de la cantidad de articulos que vamos sumando*/
.tabla__importe {
  width: 8rem;
}

.tabla__contenido {
  /*similar a tabla__encabezado, manejo los estilos del contenido de la tabla.*/
  color: var(--constrast-dark);
  font-size: var(--size-14);
  line-height: inherit;
  vertical-align: middle;
  text-align: start;
  padding: 8px;
}

.codigo_sin {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.tabla__contenido_usuario {
  /*similar a tabla__encabezado, manejo los estilos del contenido de la tabla.*/
  color: var(--constrast-dark);
  font-size: 15px;
  line-height: inherit;
  padding: 0.75rem;
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
}

.cell-actions-table {
  width: 110px;
}

.tabla--prueba {
  display: inline-flex;
}

.tabla__editable {
  /*Esto lo hice para la columna cantidad, sin el inline flex tengo los iconos y el placeholder uno arriba del otro*/
  display: inline-flex;
}

.tabla__cant {
  /*manejo ancho del placeholder de la columna cantidad*/
  width: 2rem;
  text-align: center;
  border: none;
}

.tabla__num {
  /*alineado a la derecha para las columnas numericas. No hice con alineacion izquierda, por defecto van a la izquierda.*/
  text-align: end;
}

.tabla__num_usuario {
  /*alineado a la derecha para las columnas numericas. No hice con alineacion izquierda, por defecto van a la izquierda.*/
  text-align: start;
}

.ibox-content-final-perfil {
  display: flex;
  justify-content: space-between;

  color: inherit;
  padding: 15px 20px 20px 20px;

  border-image: none;

  border-width: 1px;
  border-radius: 0 0 10px 10px;
}

.ibox-content-final-perfil-usuario {
  display: flex;
  justify-content: space-between;

  color: inherit;
  padding: 2px 2px 2px 2px;

  border-image: none;

  border-width: 1px;
  border-radius: 0 0 10px 10px;

  font-size: 12px;
}

.tabla__denom {
  text-align: start;
  width: 100%;
  transition:
    color 0.2s ease-in-out,
    text-shadow 0.2s ease-in-out;
  .agregarCarrito {
    padding-right: 10px;
    color: var(--constrast-dark);
  }
  .agregarCarrito:hover {
    color: #0056b3;
  }
  .delete-icon {
    color: var(--constrast-dark);
  }
  .delete-icon:hover {
    color: red;
  }
}

.tabla__denom:hover {
  color: #0056b3;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.renglon-posicion {
  transition: background 0.2s ease-in-out;
  &:nth-child(even) {
    background-color: #29576c12;
  }
}
.renglon-posicion:hover {
  background: #eef5ff;
  border-radius: 4px;
}

.aniadquit {
  display: none;
}

.tabla__ico {
  /*alienado centrado para los iconos*/
  gap: 20px;
  justify-items: center;
  color: var(--constrast-dark);
  padding: 10px;
  background-color: none;
}

.tabla__ico .fa-cart-plus {
  margin-right: 10px;
  /* Puedes ajustar el valor según el espacio que desees */
}

.tabla__ico .fa-file-pen {
  margin-right: 10px;
  /* Puedes ajustar el valor según el espacio que desees */
  background-color: none;
}

.tabla__contenido-btn {
  padding: 0;
  border: none;
  background-color: none;
}

.button__btn-agregar {
  background-color: #2f6178;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 100px;
}

.button__btn-delete {
  background-color: #2f6178;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 50px;
}

.button__btn-edit {
  background-color: #2f6178;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 500px;
}

.contenedor__filtro {
  display: flex;
  align-items: baseline;
  gap: 16px;
  justify-content: end;
  /* margin-left: 1%; */
  /* font-size: inherit; */
}

.contenedor__vista {
  display: flex;
  align-items: baseline;
  gap: 1px;
  /* font-size: inherit; */
  /* margin-left: 20%; */
}

.vlista {
  cursor: pointer;
  color: #1d9bf0;
}

.vlista:hover {
  transform: scale(1.2);
}

.vcuadricula {
  cursor: pointer;
  color: var(--constrast-dark);
}

.vcuadricula:hover {
  transform: scale(1.2);
  color: #1d9bf0;
}

.contenedorIniciarCompra {
  display: flex;
  flex-direction: column;
  align-items: start;
  .btnIniciarCompra {
    border: none;
    color: var(--primario);
    padding: 8px;
    background: var(--btnBackground);
    border-radius: 4px;
    display: flex;
    align-items: center;
    display: flex;
    gap: 8px;
  }
  .textoAzul {
    color: #2f6178;
    font-size: 22px;
    font-weight: lighter;
    border: none;
  }
  .textoGris {
    color: var(--constrast-dark);
    font-size: 14px;
    font-weight: lighter;
    border: none;
  }
}

.contenedorIniciarCompra {
  grid-area: client;
}

.search-input {
  grid-area: search;
}

.contenedor__filtro {
  grid-area: filter;
}

.price-with-iva {
  grid-area: priceWithIva;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-self: end;
}

.search-container {
  display: grid;
  grid-template-areas: 'client search priceWithIva filter';
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      'search search search search search search filter filter'
      'client client client client priceWithIva priceWithIva priceWithIva priceWithIva';
  }
}

.searchPerfiles {
  display: grid;
  grid-template-columns: 1fr 0fr 0fr;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.search-input {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.search-button {
  background-color: #2f6178;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contenedorprod {
  margin-top: -150px;
}

/*Media Query's*/
@media (max-width: 1024px) {
  /* .tabla__encabezado {
    height: 2rem;
  }

  .tabla__media {
    width: 3rem;
  }

  .tabla__desc {
    width: 13rem;
  }

  .tabla__precio {
    width: 4rem;
  }

  .tabla__importe {
    width: 4rem;
  } */
  /* .search-input {
    width: 95%;
  } */

  /* .contenedor__filtro {
    gap: 5px;
    /* font-size: 13px; 
    margin-top: 1%;
    width: 25rem;
    display: flex;
    justify-content: flex-end;
  } */

  /* .contenedor__vista {
    margin-left: 5%;
  } */

  /*   .opciones__vista {
    gap: 5px;
    /* font-size: 13px; */

  /* .search-container {
    flex-direction: column;
  } */
}

@media (max-width: 320px) {
  .codigo {
    display: none;
  }
  /* .contenedor__filtro {
    width: 90%;
  } */
}

@media (min-width: 320px) and (max-width: 480px) {
  .codigo {
    display: none;
  }

  .cantidad {
    display: none;
  }

  .importe {
    display: none;
  }

  .añadquit {
    display: none;
  }

  /* .contenedor__filtro {
    width: 90%;
    display: flex;
    justify-content: flex-end;
  } */
}

@media (min-width: 480px) and (max-width: 624px) {
  .cantidad {
    display: none;
  }

  .importe {
    display: none;
  }

  .añadquit {
    display: none;
  }
}

@media (min-width: 624px) and (max-width: 767px) {
  .añadquit {
    display: none;
  }

  .tabla__desc {
    width: 15rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .aniadquit {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  /* .search-input {
    width: 64%;
  } */
  .aniadquit {
    display: none;
  }
}

/* PANTALLAS GRANDES */
@media (min-width: 1281px) {
  .aniadquit {
    display: none;
  }
}

.tabla {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
}

.cantidad {
  display: none;
}

.importe {
  display: none;
}
