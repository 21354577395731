.grandcontainer__catalogo {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  margin-top: 1%;
  font-size: var(--size-14);
  padding: 0 16px;
}

.catalogosencillo {
  margin-top: 16px;
}

.filters-pills {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 16px;
}

.pill {
  background: #e7e7e7;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 3px;
}

.grandcontainer__catalogocuadricula {
  display: flex;
  justify-content: center;
}
/*INICIO HAMBURGUESA FILTROS*/

.hamburger {
  display: block;
  cursor: pointer;
  transition: margin-left 0.4s;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px auto;
  transition: 0.4s;
}

#toggle {
  position: absolute;
  opacity: 0;
}

#burger__toggle:not(:checked) + .hamburger {
  margin-left: 17%;
}

#toggle:checked + .hamburger .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

#toggle:checked + .hamburger .bar:nth-child(2) {
  opacity: 0;
}
#toggle:checked + .hamburger .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

#toggle:checked + .hamburger ~ .nav__filtros {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
}

/* #novisiblefiltros + .hamburger ~ .nav__filtros{
    display: none;
  } */

/*FIN HAMBURGUESA FILTROS*/

/*INICIO CSS BotonesVista ESTOS BOTONES ESTAN EN AMBOS CATALOGOS*/
.iconosvista {
  display: flex;
  gap: 8px;
}

.boton-activo {
  color: #0056b3;
}

.boton {
  color: var(--constrast-dark);
  cursor: pointer;
}

.boton:hover {
  transform: scale(1.2);
}
/*FIN CSS BotonesVista*/

/*CATALOGO CUADRICULA*/
.contenedorcuadricula {
  color: var(--constrast-dark);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 16px;
}

.catalogocuadricula {
  margin: 24px 0;
}

.itemcuad {
  border-radius: 5px;
  background: white;
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-flow: column nowrap;
  align-items: center;
  box-shadow: var(--sombra);

  h3 {
    margin: 0;
    font-size: 14px;
    text-align: left;
  }
}

.itemcuad:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.contenedor-imagencatalogcuad {
}

.buttons-actions-carts-content {
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
}

/* .img-producto{
    max-width: 200px;
    max-height: 200px;
} */

.linea {
  border: none;
  border-top: 1px solid var(--constrast-dark);
}

.info-producto {
  display: flex;
  justify-content: center;
  padding: 1px 30px;
  font-size: 14px;
  color: #2f6178;
  height: 15%;
}

.contenedor-codigocuadric {
  margin-top: 1px;
}

.stock-producto {
  font-size: 12px;
}

.precio-producto {
  justify-content: center;
  font-weight: 600;
}

.select-cant {
  width: 2rem;
  text-align: center;
  border: none;
}

.btn-agregarcarrito:hover {
  transform: scale(1.03);
}

/*FIN CATALOGO CUADRICULA*/

/*media queries  */
@media (max-width: 480px) {
  .catalogosencillo {
    margin-left: 0%;
  }
  .itemcuad {
    margin-left: 0;
  }
}
/* @media (max-width: 1024px){
    .grandcontainer__catalogo{
        justify-content: center;
    }
  } */

.paginacion {
  display: flex;
  justify-content: center;
  margin-top: 1%;
  align-items: baseline;
}

.iconleft {
  margin-right: 5px;
}

.iconright {
  margin-left: 5px;
}

.paginacion-antes {
  margin-right: 10px;
  color: #0056b3;
  border: none;
  background-color: transparent;
  font-size: inherit;
}

.paginacion-centro {
  font-size: inherit;
}

.paginacion-despues {
  margin-left: 10px;
  color: #0056b3;
  border: none;
  background-color: transparent;
  font-size: inherit;
}

.paginacion-antes:hover {
  background: whitesmoke;
}

.paginacion-despues:hover {
  background: whitesmoke;
}

.catalog-grid-content-detail {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 12px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.actions-bottom {
      justify-content: end;
    }
  }
}

.actions-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  .buttons-actions-cart {
    padding: 0;
  }
}

@keyframes showAll {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 60vh;
  }
}

.filter-list {
  .nav__filtros {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > button {
      font-size: 24px;
    }
  }
  .filters-container {
    width: 100%;
    .group-items.is-open {
      ul {
        animation: showAll 0.3s ease-in-out forwards;
        > li {
          label {
            cursor: pointer;
          }
          &:hover {
            font-weight: 500;
          }
        }
      }
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 0;
    overflow: auto;
    height: auto;
  }
  #openFilter {
    text-align: left;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    svg {
      width: 14px;
    }
  }
}
label.filter-checkbox {
  display: flex;
  gap: 8px;
  cursor: pointer;
  &:hover {
    font-weight: 500;
  }
}

.clean-filters {
  display: block;
  color: #0275ff;
}

.loading-properties {
  text-align: center;
  margin-top: 48px;
  width: 100%;
}
