.contenedorGridOrder {
  color: var(--constrast-dark);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(231px, 1fr));
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  > article {
    border-radius: 5px;
    background: white;
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: var(--sombra);
    font-size: 12px;
    > header {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--constrast-dark);
      font-weight: 600;
    }
    > ul {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;

      > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .textBold {
          font-weight: bolder;
        }
      }
    }
  }
}
