.grandContainerPerfiles {
  margin-top: 1%;
  font-size: var(--size-14);
  padding: 0 16px;
  .titleProfilesUser {
    font-size: 24px;
    margin: 0;
    color: var(--tituloColor);
    font-weight: 300;
    padding: 8px;
  }
  .searchProfileContainer {
    display: grid;
    grid-template-areas: 'btn search';
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'btn'
        'search';
    }
    .btnAddProfile {
      grid-area: btn;
      border: none;
      color: var(--primario);
      padding: 8px;
      background: var(--btnBackground);
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      gap: 4px;
      align-items: center;

      @media (max-width: 768px) {
        width: auto;
        justify-self: start;
      }
      .svgNewProfile {
        width: 18px;
      }
    }
    .searchProfile {
      grid-area: search;
      padding: 4px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
    }
  }
  .contenedorCardProfiles {
    margin-top: 24px;

    .cardProfiles {
      display: grid;
      grid-template-areas: 'denom depo prec eliminar';
      grid-template-columns:
        3fr minmax(min-content, 3fr) minmax(min-content, 3fr)
        minmax(auto, 1fr);
      align-items: center;
      justify-items: center;
      color: var(--constrast-dark);
      font-size: var(--size-14);
      gap: 16px;
      padding: 16px;
      transition: background 0.2s ease-in-out;
      &:nth-child(even) {
        background-color: #29576c12;
      }
      .profileDenom {
        grid-area: denom;
        width: 100%;
        text-align: start;
        font-weight: bold;
        text-transform: uppercase;
        > input {
          width: 100%;
        }
      }
      .profileDepo {
        grid-area: depo;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        align-items: flex-start;
        gap: 8px;
        > span {
          font-weight: 600;
          text-align: start;
        }
        > div {
          display: flex;
          gap: 8px;
          text-align: start;
          flex-wrap: wrap;
        }
        > button {
          border: none;
          background: transparent;
          color: #0056b3;
          font-size: 12px;
          font-weight: 300;
        }
      }
      .profilePrec {
        grid-area: prec;
      }
      .profileClear {
        grid-area: eliminar;
        height: 100%;
        justify-self: end;
        align-content: center;
        display: flex;
        gap: 16px;
        > button {
          padding: 8px;
          align-self: center;
          border-radius: 3px;
          transition: all 0.3s ease-in-out;
          &:hover {
            border: 1px solid #14141459;
          }
        }
      }
      @media (width < 768px) {
        grid-template-areas:
          'denom denom denom eliminar'
          'depo depo prec prec';
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    .btnTrashSave {
      background: transparent;
      .imgTrashSave {
        width: 16px;
      }
    }

    .cardProfiles:hover {
      background: #eef5ff;
      border-radius: 4px;
    }
  }
}
