.badge {
  background: #007bff;
  color: white;
  border-radius: 50%;
  min-width: 22px;
  height: 22px;
  display: inline-block;
  font-size: 12px;
  border: 2px solid white;
  pointer-events: none;
  text-align: center;
  padding-right: 1px;
}
