.modal-art {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.contenedor-cruz-y-logo {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.close-art {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  height: 50px;
}
.contenedor-modalcabecera {
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 8px;
}

.contenedor-logo {
  display: flex;
  width: 100%;
  justify-content: center;
}

.contenedor-modal-titulo {
  display: flex;
  justify-content: center;
}
.contenedor-modal-estado {
  display: flex;
  justify-content: center;
}
.modal-estado {
  font-size: 14px;
  background: #00c6ab;
  color: white;
  border-radius: 3px;
  padding: 4px 8px;
}
.modal-codigo {
  font-weight: lighter;
  color: black;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.modal-titulo {
  font-weight: lighter;
  color: #2f6178;
  font-size: 18px;
  width: 95%;
  line-height: 1.6;
  text-align: left;
}

.contenedor-modal-linea {
  display: flex;
  justify-content: center;
}
.modal-linea {
  border-top: 1px solid var(--constrast-dark);
  margin: 0 auto;
  width: 80%;
}

.contenedor-codigo {
  display: flex;
  /* justify-content: space-around; */
  width: 100%;
  align-items: center;
}

.modal-descripcion {
  font-size: 15px;
  font-weight: 300;
  color: var(--constrast-dark);
  text-shadow: none;
}

.modal-contenedor-stock {
  display: flex;
}

.modal-precio {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  font-size: 30px;
  font-weight: 600;
  color: var(--constrast-dark);
  text-shadow: none;
}

.modal-fecha {
  color: var(--constrast-dark);
  font-size: 14px;
  display: flex;
  justify-content: center;
  font-weight: 400;
  margin: 0;
}

.modal-articulo-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.articulo-button__open_modal {
  border: 0;
  background: none;
  text-align: left;
  > span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--secundario);
    font-weight: 400;
  }
  &:hover {
    background: none;
    color: #2f6178;
  }
}

/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
@media (max-width: 320px) {
  .modal-content-art {
    width: 95%;
  }
  .modal-titulo {
    font-size: 20px;
  }
  .modal-codigo {
    font-size: 19px;
  }
}

/* CELULARES MOVILES */
/* @media (min-width: 321px) and (max-width: 480px) {
  .modal-content-art {
    width: 95%;
  }
  .modal-titulo {
    font-size: 20px;
  }
  .modal-codigo {
    font-size: 19px;
  }
} */

/* BAJA RESOCION, IPAD TABLET */
/* @media (min-width: 481px) and (max-width: 624px) {
  .modal-content-art {
    width: 65%;
  }
  .modal-titulo {
    font-size: 20px;
  }
  .modal-codigo {
    font-size: 19px;
  }
} */

@media screen and (max-width: 1600px) {
  .modal-descripcion {
    font-size: 14px;
  }
}
