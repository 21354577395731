.contenedorComprasAnt {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  background: var(--fondo);
  margin-top: 1%;
  box-shadow: var(--sombra);
  border-radius: 3px;
  padding: 8px;
  margin-top: 2rem;
  .tituloCompAnt {
    display: flex;
    justify-content: center;
  }
  .lineaComprasAnt {
    width: 75%;
    border-top: 1px solid black;
  }
  .contenedorCardsAnt {
    display: flex;
    width: 120px;
    border: 2px solid whitesmoke;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 3px;
    position: relative;
  }
  .imgComprasAnt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 64px;
    max-height: 64px;
    .addItems {
      position: absolute;
      top: 0;
      right: 0;
    }
    .addItems:hover {
      transform: scale(1.2);
    }
  }
  .comprasAntTotArt {
    display: flex;
    flex-direction: column;
    align-items: center;
    .comprasAntTot {
      font-size: 16px;
      font-weight: 600;
      color: #80808b;
    }
    .comprasAntArt {
      font-size: 12px;
      color: #2f6178;
      font-weight: 500;
    }
  }
}
