.buscarClientes:focus {
  outline: none;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.sugerencias {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--constrast-dark);
  cursor: pointer;
  .renglonSugerencias:hover {
    color: #0056b3;
    font-weight: 500;
  }
}
