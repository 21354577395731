.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.modal-content-global {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  .closeBtn {
    align-self: flex-end;
    font-size: 24px;
  }
}
.contenedorClieyBusc {
  display: grid;
  overflow-y: auto;
  .clientesTitu {
    font-size: 20px;
    color: #2f6178;
    font-weight: 300;
    padding: 8px;
  }
  .buscarClientes {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
  }
}
.contenedorClieyBusc:focus {
  outline: none;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.btnAceptar {
  border-radius: var(--border-radius);
  background: var(--btnBackgroundColor);
  border: none;
  color: var(--primario);
  padding: 8px;
  margin-top: 4%;
}
