.cart {
  color: white;
  cursor: pointer;
}
.text {
  font-size: 10px;
}
.textGreen {
  color: #29576c;
  background: white;
  width: 14px;
  display: inline-block;
  height: 14px;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
}
