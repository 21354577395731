.buttons-actions-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  > span {
    width: 21px;
    text-align: center;
    &.counter {
      font-weight: 800;
    }
  }
  .selectorItemsCatalog {
    width: 24px;
    height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    background: white;

    > img {
      width: 100%;
    }
  }
}
