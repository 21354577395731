/* Import Google font - Poppins */
:root {
  --primario: #fff;
  --colorPagPrimario: #29576c;
  --backgroundPrincipal: #ffffff;
  --secundario: #0056b3;
  --fondo: #ffffff;
  --sombra: 1px 1px 5px -3px black;
  --btnBackground: #2d5e74;
  --tituloColor: #2d5e74;
  --btnBackgroundColor: #2d5e74;
  --border-radius: 4px;
  --constrast-dark: #3b3b3b;
  --size-14: 0.88rem;
  --colorDecoracion: #808080;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.input {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.sidebar {
  position: fixed;
  height: 100%;
  /* background: linear-gradient(90deg, rgb(0 0 0) 0%, rgba(41, 87, 108, 1) 100%); */
  background: var(--colorPagPrimario);
  padding: 15px;
  z-index: 98;
  text-align: left;
  transition: left 0.4s;
  /* Cambio aquí para la animación */
  /*overflow: auto; */
  top: 0;
}

.sidebar .imagen_logo {
  max-width: 100%;
  /* z-index: 99; */
  transition: left 0.4s;
  padding: 8px;
}

.filtro-seleccionado {
  width: 80vw;
}

.cruzsidebar {
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}

.logo {
  font-size: 25px;
  padding: 0 15px;
}

.sidebar a {
  color: #fff;
  text-decoration: none;
}

.link-logo-sidebar {
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  height: 100px;
}

.img .imagen_logo {
  /* margin-right: 75%;
    margin-bottom: 35%; */
  height: auto;
}

.sidebar-logoycruz {
  display: flex;
  justify-content: center;
  height: 10vh;
}

.menu-content {
  position: relative;
  height: 100%;
  /* width: 100%;*/
  margin-top: 40px;
  overflow-y: scroll;
  padding: 8px;
}

.menu-content::-webkit-scrollbar {
  display: none;
}

/*
    ul {
      list-style-type: none;
    }*/
.menu-items {
  /*height: 100%;
      width: 100%;*/
  list-style: none;
  transition: all 0.4s ease;
  list-style-type: none;
}

.submenu {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: calc(-100% - 26px);
  background: var(--colorPagPrimario);
  display: none;
  padding: 25px;
}

.menu-title {
  color: #fff;
  font-size: 14px;
  padding: 15px 20px;
}

.submenu .menu-title {
  border-radius: 12px;
  cursor: pointer;
}

.submenu .menu-title i {
  margin-right: 10px;
}

.item a,
.submenu-item {
  padding: 16px;
  display: inline-block;
  /*width: 100%;*/
  border-radius: 12px;
}

.item i {
  font-size: 12px;
}

.item a:hover,
.submenu-item:hover,
.submenu .menu-title:hover {
  background: rgba(255, 255, 255, 0.1);
}

.submenu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.show-submenu ~ .submenu {
  display: block;
}

.submenu-active .menu-items {
  transform: translateX(-60%);
}

/*INICIO PRUEBAS BURGER*/
#burger__toggle {
  position: absolute;
  opacity: 1;
  /* Cambio de 0 a 1 para que esté marcado por defecto */
  display: none;
}

.burger {
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}

#burger__toggle:not(:checked) + .burger {
  margin-left: 0;
}

#burger__toggle:not(:checked) + .burger ~ .sidebar {
  /*top: 0;  Cambio aquí para mostrar el menú */
  left: 0%;
  width: 17%;
}

#burger__toggle:not(:checked) + .burger ~ .sidebar ~ .grand_conteiner {
  margin-left: 17%;
}

.grand_conteiner {
  margin-left: 0;
  /* Asegura que no haya un margen izquierdo inicial*/
  transition: margin-left 0.4s;
  /* Agrega una transición para el margen izquierdo */
}

/*FIN PRUEBAS BURGER*/

/*WHATSAPP INICIO*/

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

.my-float {
  margin-top: 16px;
}

/*WHATSAPP FIN*/

/* ---- NAVBAR INICIO ----*/
.navbar {
  /*position: fixed;*/
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 16px;
  background: var(--colorPagPrimario);
  text-align: left;
  align-items: center;
}

.navbar-black {
  /*position: fixed;*/
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  color: #fc0000;
  padding: 10px 10px;
  /*font-size: 25px;*/
  /*background: #2f6178;*/
  /* background: linear-gradient(
    265deg,
    rgba(15, 22, 26, 0.662) 0%,
    rgba(41, 87, 1rgb (82, 86, 88))
  ); */
  /*cursor: pointer;*/
  text-align: left;
}

.noDesbordaTexto {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.navbar #sidebar-close {
  cursor: pointer;
}

.navbar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.navbar-right > a {
  color: #fff;
  font-size: 17px;
}

.contenedor-logout {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout {
  font-size: 15px;
}

/* ---- NAVBAR FIN ----*/
/* ---- NAVBAR2 INICIO ----*/
.navbar2 {
  width: 100%;
  height: 80px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  transition: all 0.5s ease;
  color: black;
  padding: 10px;
  font-size: 20px;
  background: whitesmoke;
}

.imgLogoEmpresa {
  width: 300px;
  height: 70px;
}

/* ---- NAVBAR2 FIN ----*/

.navbar,
.navbar2,
.main,
.footer {
  /*left: 260px;*/
  /* width: calc(100% - 260px);*/
  /* width: 100%;*/
  transition: all 0.5s ease;
  /* z-index: 1000;*/
}

.footersmall {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.sidebar.close ~ .navbar,
.sidebar.close ~ .navbar2,
.sidebar.close ~ .main,
.sidebar.close ~ .footer {
  left: 0;
  width: 100%;
}

/*----------- mq -----------*/
@media (max-width: 450px) {
  .sidebar.close ~ .navbar,
  .sidebar.close ~ .navbar2,
  .sidebar.close ~ .main,
  .sidebar.close ~ .footer {
    left: 0;
    width: 100%;
    margin-left: 1px;
    width: 100%;
  }

  .imgLogoEmpresa {
    width: 150px;
    height: 35px;
  }
}

/*----------- FIN -----------*/
/*-------------------------------------------------------------------*/
.finalform {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  .btnOlvidePass {
    border: none;
    background: transparent;
    color: #0056b3;
    font-size: 12px;
    font-weight: 300;
    /* margin: 11px; */
    margin-top: 10px;
  }
}

.grand_conteiner {
  /*width: 83%;*/
  width: 100%;
  box-sizing: border-box;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /*justify-content: space-between;*/
}

.topbar {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
}

/*MAIN*/
.main {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /*background: #fff;*/
  flex-direction: column;
}

.main h1 {
  color: #11101d;
  text-align: center;
}

.main article {
  justify-content: center;
  text-align: left;
}
/*FIN MAIN*/

/*Nuevos Usuarios*/

.addNewUserContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  .titleNewUser {
    font-size: 24px;
    margin: 0;
    color: var(--tituloColor);
    font-weight: 300;
    padding: 8px;
  }
  .paragraphNewUser {
    font-size: var(--size-14);
    color: var(--constrast-dark);
  }
  .cardNewUser {
    border-radius: 5px;
    background: white;
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: var(--sombra);
    margin: 24px;
    .titleNewUserCard {
      color: var(--constrast-dark);
      font-weight: 500;
    }
    .btnSendNewUser {
      border: none;
      color: var(--primario);
      padding: 8px;
      margin-top: 4%;
      background: var(--btnBackground);
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 4px;
      .svgSend {
        width: 16px;
      }
    }
  }
}

/*FIN Nuevos Usuarios*/

/*CARRO DE COMPRAS*/

/**,
  ::after,
  ::before {
    box-sizing: border-box;
  }*/

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

strong {
  font-weight: bolder;
}

a {
  color: #0056b3;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: underline;
}

button {
  border-radius: 0;
  background: none;
  border: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

button:not(:disabled) {
  cursor: pointer;
}

h2,
h3,
h4,
h5 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

hr {
  /*margin-top: 1rem;
    margin-bottom: 1rem;*/
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small {
  font-size: 80%;
  font-weight: 400;
}

.col-md-3,
.col-md-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 5px;
}

@media (min-width: 768px) {
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    /* margin-top: -270px;      */
  }
}

.navbar-ini {
  width: 100%;
  /*background: linear-gradient(90deg, rgba(81,131,156,1) 0%, rgba(47,97,120,1) 97%);*/
  /* background: linear-gradient(
    265deg,
    rgba(83, 171, 215, 0.6615021008403361) 0%,
    rgba(41, 87, 108, 1) 100%
  ); */
  padding-bottom: 0px;
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
  padding-left: 2px;
  padding-right: 2px;
  align-items: center;
}

.navbar-ini a {
  color: rgba(41, 87, 108, 1);
}

ul {
  list-style: none;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn:disabled {
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-group {
  position: relative;
  /* display: -ms-inline-flexbox; */
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}

.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-muted {
  color: #6c757d !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-arrow-left:before {
  content: '\f060';
}

.fa-gift:before {
  content: '\f06b';
}

.fa-shopping-cart:before {
  content: '\f07a';
}

.fa-phone:before {
  content: '\f095';
}

.fa-long-arrow-right:before {
  content: '\f178';
}

.fa-trash:before {
  content: '\f1f8';
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

h2,
h3,
h4,
h5 {
  font-weight: 100;
}

h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h3,
h4,
h5 {
  margin-top: 5px;
  font-weight: 600;
}

button:focus {
  outline: 0 !important;
}

.btn {
  border-radius: 3px;
  font-size: inherit;
}

.btn:focus {
  box-shadow: none;
}

.btn-xs {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}

.btn-sm {
  font-size: 0.8rem;
}

.btn-primary.btn-outline {
  color: #ffffff;
}

.btn-primary.btn-outline:hover {
  color: #fff;
}

.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-primary {
  color: #fff;
  background-color: #2f6178;
  border-color: #2f6178;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #2f6178;
  border-color: #2f6178;
  color: #ffffff;
}

.btn-primary:disabled {
  color: #fff;
  background-color: #2f6178;
  border-color: #2f6178;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #2f6178;
  border-color: #2f6178;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-white {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white:active:focus,
.btn-white:active:hover {
  color: inherit;
  border: 1px solid #d2d2d2;
}

.btn-white:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-white:active {
  background-image: none;
}

.form-control,
.form-control:focus {
  box-shadow: none;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}

.btn:focus {
  outline: none !important;
}

.form-control {
  font-size: 0.9rem;
}

.form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 10px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition:
    border-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

.form-control:focus {
  border-color: #2f6178;
}

a {
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none;
}

.font-bold {
  font-weight: 600;
}

.wrapper {
  padding: 0 20px;
  margin-top: 315px;
}

.wrapper-content {
  padding: 20px 10px 40px;
}

.text-navy {
  color: #2f6178 !important;
}

.text-muted {
  color: #888888 !important;
}

.product-name {
  font-size: 16px;
  font-weight: 600;
  color: #676a6c;
  display: block;
  margin: 2px 0 5px 0;
}

.product-name:hover,
.product-name:focus {
  color: #2f6178;
}

/*LOGIN*/
.granContenedorLogin {
  background: url('../../components/auth/assets/waveLogin.svg');
  background-size: cover;
  width: 100%;
  .contenedor {
    display: flex;
    /* width: 26%; */
    /* max-width: 1000px; */
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  .granContenedorLogin .contenedor .formulario {
    width: 95%;
  }
}

/* .logo {
  flex-basis: 40%;
}

.logo img {
  max-width: 100%;
  height: auto;
} */
.contenedorLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  .logoLogin {
    width: 30%;
    margin-bottom: 8px;
  }
  .logoTitle {
    color: #eead33;
    font-weight: 700;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
  }
}

.formulario {
  width: 450px;
  background-color: #ffffff;
  /* flex-basis: 50%; */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* height: min-content; */
}

.formulario h2 {
  font-size: 24px;
  margin-bottom: 8px;
  margin-top: 0;
}

.formulario label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-align: start;
}

.error-message {
  background-color: rgba(84, 228, 228, 0.116);
  border-radius: 5px;
  font-size: small;
  padding: 5px;
  text-align: start;
  margin-bottom: 8px;
}

.formulario input[type='text'],
.formulario input[type='email'],
.formulario input[type='password'] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 8px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.formulario input[type='submit'] {
  border: none;
  color: var(--primario);
  padding: 8px;
  background: var(--btnBackground);
  border-radius: 6px;
  font-size: 14px;
}

.formulario input[type='submit']:hover {
  background: linear-gradient(
    265deg,
    rgba(83, 171, 215, 0.6615021008403361) 0%,
    rgba(41, 87, 108, 1) 100%
  );
  color: white;
}

/*LOGIN*/

.container {
  text-align: left;
}

.container:after {
  content: '';
  display: table;
  clear: both;
  margin: 0 auto;
}

.left {
  float: left;
}

.center {
  margin: 0 auto;
  display: inline-block;
  color: black;
}

.vh-100 {
  margin-top: 12%;
  display: flex;
}

.page {
  padding: 8px;
  height: 100%;
  background: #fcfcfc;
}

.footer {
  /*background: #FFF;*/
  margin-top: auto;
}

/*-------------------------------------------------*/
/* ----------------- Perfiles ---------------------*/
.grandcontainer__perfiles {
  margin-top: 1%;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.contenedor-tablaperfil {
  margin-top: 1%;
}
/*-----------------Fin Perfiles--------------------*/
/*-------------------------------------------------*/
@media (max-width: 1024px) {
  #burger__toggle:not(:checked) + .burger ~ .sidebar ~ .grand_conteiner {
    margin-left: 0%;
  }

  #burger__toggle:not(:checked) + .burger ~ .sidebar {
    left: -17%;
  }

  #burger__toggle:checked + .burger ~ .sidebar {
    left: 0;
  }

  .logout {
    display: none;
  }

  .logout-icono {
    font-size: 25px;
  }
}

@media (min-width: 1025px) {
  .burger {
    display: none;
  }

  #burger__toggle:checked + .burger ~ .sidebar ~ .grand_conteiner {
    margin-left: 17%;
  }

  #burger__toggle:checked + .burger ~ .sidebar {
    left: 0%;
    width: 17%;
  }
}

@media (max-width: 320px) {
  .navbar-right {
    gap: initial;
  }

  .sidebar {
    width: 68%;
  }

  .grand_conteiner {
    width: initial;
  }

  .navbar {
    width: initial;
  }

  .navbar-right > a {
    font-size: 14px;
  }

  h3 {
    font-size: 14px;
  }

  .descripcion {
    width: 20%;
  }

  .stock {
    display: none;
  }

  .precio {
    width: 10%;
  }
}

/* CELULARES MOVILES */
@media (min-width: 320px) and (max-width: 480px) {
  .sidebar {
    width: 68%;
  }

  .burger {
    top: 1.6%;
    left: 1.6%;
  }

  .navbar-right > a {
    font-size: 13px;
  }

  #toggle:checked + .hamburger ~ .nav__filtros {
    top: 135px;
  }
}

/* BAJA RESOCION, IPAD TABLET */
@media (min-width: 481px) and (max-width: 767px) {
  .sidebar {
    width: 43%;
  }

  /* .formulario input[type='submit'] {
    height: 43px;
    padding: 4px 8px;
  } */

  /* .finalform {
    align-items: center;
  } */

  /* .finalform a {
    margin-top: -25px;
  } */

  .col-md-3,
  .col-md-9 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .contenedor {
    display: block;
    justify-content: center;
  }

  /* .formulario input[type='submit'] {
    height: 43px;
    padding: 4px 8px;
  } */

  /* .finalform {
    align-items: center;
  } */

  /* .finalform a {
    margin-top: -25px;
  } */

  #toggle:checked + .hamburger ~ .nav__filtros {
    top: 119px;
  }
}

/* TABLETAS Y IPAD LANDSCAPE */
@media (min-width: 768px) and (max-width: 1024px) {
  .sidebar {
    width: 28%;
  }
}

/* NOTEBOOKS Y ESCRITORIO */
@media (min-width: 1025px) and (max-width: 1280px) {
  #burger__toggle:not(:checked) + .burger {
    margin-left: 0;
  }

  #burger__toggle:not(:checked) + .burger ~ .sidebar {
    /*top: 0;  Cambio aquí para mostrar el menú */
    left: 0%;
    width: 21%;
  }

  #burger__toggle:not(:checked) + .burger ~ .sidebar ~ .grand_conteiner {
    margin-left: 21%;
  }

  .cruzsidebar {
    display: none;
  }

  #toggle:checked + .hamburger ~ .nav__filtros {
    top: 133px;
  }
}

@media (width < 768px) {
  .topbar {
    position: fixed;
    width: 100%;
  }
  .page {
    padding-top: 95px;
  }
}

.fg-8 {
  display: flex;
  /* gap: 8px; */
}

.fg-4 {
  display: flex;
  gap: 4px;
}

.modal-content-global {
  background-color: #fefefe;
  padding: 24px;
  width: 100%;
  box-shadow: 1px 0 20px rgb(0 0 0 / 75%);
  max-width: 500px;
  animation: open-aside-modal 0.3s ease-in forwards;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: stretch;
}

.closeBtn {
  border: none;
  background: transparent;
  font-size: 24px;
  align-self: end;
}

.modal-background {
  position: fixed;
  background: #0000004f;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.f-c {
  display: flex;
  flex-direction: column;
}

.g-8 {
  gap: 8px;
}

@keyframes open-aside-modal {
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

/* PANTALLAS GRANDES */
@media (min-width: 1281px) {
  .cruzsidebar {
    display: none;
  }
}

/*FIN MQ*/

/*@media (max-width: 1629px){


    .col-md-3, .col-md-9 {
      margin-top: 280px;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
  }

    .contenedor{
      justify-content: center;
      flex-direction: column;
    }
  
    .formulario input[type="submit"] {
      height: 43px;
      padding: 4px 8px;
    }
    .finalform{
      align-items: center;
      
    }
    .finalform a{
      margin-top: -25px;
    }
  }
*/
